import 'swiper/swiper-bundle.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import '../css/load.scss';

import Swiper from 'swiper/bundle';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

import Tokenfield from 'tokenfield';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const $loader = $('.app-loader');
	let newsLoading = false;
	let modelsLoading = false;
	let photographersLoading = false;

	// Header
	initHeaderMenuToggler();
	blurHeader();
	// Sliders
	initSliderSnaps();
	initSliderNews();
	initSliderTweets();
	// Galleries
	initPhotoSwipe('.gallery');
	initPhotoSwipe('.gallery-vertical');
	initPhotoSwipe('.accordion__image');
	// Form Styling
	handleSelectStyles();
	initChipTokenField();
	// Filter Form
	handleFiltersForm();
	handleFiltersButton();
	clearFiltersButton();
	handleAllCheckboxLogic();
	// AJAX Load more
	handleAjaxLoadMoreModels();
	document.addEventListener('touchmove', handleAjaxLoadMoreNews, false);
	document.addEventListener('scroll', handleAjaxLoadMoreNews, false);
	document.addEventListener('touchmove', handleAjaxLoadMorePhotographers, false);
	document.addEventListener('scroll', handleAjaxLoadMorePhotographers, false);
	// MISC
	scrollToSection();
	initAccordion();

	function addLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.addClass('active');
	}

	function removeLoader() {
		if (!$loader.length) {
			return;
		}

		$loader.removeClass('active');
	}

	function initHeaderMenuToggler() {
		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			e.preventDefault();
		});

		$('.btn-close').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
		});
	}

	function initSearchFromIcon() {
		$('.search__btn').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.search-form').toggleClass('active');
		});
	}

	function initChipTokenField() {
		if (document.getElementById('data-filter') === null) {
			return;
		}

		let tokenField = new Tokenfield({
			el: document.getElementById('data-filter'),
			itemName: 'field-data-filter',
			remote: {
				url: appData.ajaxUrl,
				params: {
					action: 'app_get_image_data_terms'
				}
			}
		});

		$('.btn-clear-filters').on('click', function (e) {
			e.preventDefault();

			tokenField.emptyItems();
		});
	}

	function initSliderSnaps() {
		$('.slider--snaps .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				slidesPerView: 'auto',
				spaceBetween: 16,
				loop: false,
				autoplay: false,
				allowTouchMove: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					300: {
						spaceBetween: 16,
						loop: true,
						allowTouchMove: true
					},
					782: {
						slidesPerView: 'auto',
						spaceBetween: 16
					}
				}
			});
		});
	}

	function initSliderNews() {
		$('.slider--news .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				slidesPerView: 'auto',
				spaceBetween: 16,
				loop: false,
				autoplay: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					300: {
						loop: true,
						spaceBetween: 16
					},
					782: {
						slidesPerView: 'auto',
						spaceBetween: 16
					}
				}
			});
		});
	}

	function initPhotoSwipe(gallerySelector) {
		if (document.querySelectorAll(gallerySelector).length < 0) {
			return;
		}

		var parseThumbnailElements = function (el) {
			var thumbElements = el.childNodes,
				numNodes = thumbElements.length,
				items = [],
				figureEl,
				linkEl,
				size,
				item;

			for (var i = 0; i < numNodes; i++) {
				figureEl = thumbElements[i]; // <figure> element

				// include only element nodes
				if (figureEl.nodeType !== 1) {
					continue;
				}

				linkEl = figureEl.children[0]; // <a> element

				size = linkEl.getAttribute('data-size').split('x');

				// create slide object
				item = {
					src: linkEl.getAttribute('href'),
					w: parseInt(size[0], 10),
					h: parseInt(size[1], 10)
				};

				if (figureEl.children.length > 1) {
					// <figcaption> content
					item.title = figureEl.children[1].innerHTML;
				}

				if (linkEl.children.length > 0) {
					// <img> thumbnail element, retrieving thumbnail url
					item.msrc = linkEl.children[0].getAttribute('src');
				}

				item.el = figureEl; // save link to element for getThumbBoundsFn
				items.push(item);
			}

			return items;
		};

		// find nearest parent element
		var closest = function closest(el, fn) {
			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		// triggers when user clicks on thumbnail
		var onThumbnailsClick = function (e) {
			e = e || window.event;
			e.preventDefault ? e.preventDefault() : (e.returnValue = false);

			var eTarget = e.target || e.srcElement;

			// find root element of slide
			var clickedListItem = closest(eTarget, function (el) {
				return el.tagName && el.tagName.toUpperCase() === 'FIGURE';
			});

			if (!clickedListItem) {
				return;
			}

			// find index of clicked item by looping through all child nodes
			// alternatively, you may define index via data- attribute
			var clickedGallery = clickedListItem.parentNode,
				childNodes = clickedListItem.parentNode.childNodes,
				numChildNodes = childNodes.length,
				nodeIndex = 0,
				index;

			for (var i = 0; i < numChildNodes; i++) {
				if (childNodes[i].nodeType !== 1) {
					continue;
				}

				if (childNodes[i] === clickedListItem) {
					index = nodeIndex;
					break;
				}
				nodeIndex++;
			}

			if (index >= 0) {
				// open PhotoSwipe if valid index found
				openPhotoSwipe(index, clickedGallery);
			}
			return false;
		};

		// parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function () {
			var hash = window.location.hash.substring(1),
				params = {};

			if (hash.length < 5) {
				return params;
			}

			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}

			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}

			return params;
		};

		var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				gallery,
				options,
				items;

			items = parseThumbnailElements(galleryElement);

			// define options (if needed)
			options = {
				// define gallery index (for URL)
				galleryUID: galleryElement.getAttribute('data-pswp-uid'),

				getThumbBoundsFn: function (index) {
					// See Options -> getThumbBoundsFn section of documentation for more info
					var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
						pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
						rect = thumbnail.getBoundingClientRect();

					return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
				}
			};

			// PhotoSwipe opened from URL
			if (fromURL) {
				if (options.galleryPIDs) {
					// parse real index when custom PIDs are used
					// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
					for (var j = 0; j < items.length; j++) {
						if (items[j].pid == index) {
							options.index = j;
							break;
						}
					}
				} else {
					// in URL indexes start from 1
					options.index = parseInt(index, 10) - 1;
				}
			} else {
				options.index = parseInt(index, 10);
			}

			// exit if index not found
			if (isNaN(options.index)) {
				return;
			}

			if (disableAnimation) {
				options.showAnimationDuration = 0;
			}

			// Pass data to PhotoSwipe and initialize it
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();
		};

		// loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll(gallerySelector);

		for (var i = 0, l = galleryElements.length; i < l; i++) {
			galleryElements[i].setAttribute('data-pswp-uid', i + 1);
			galleryElements[i].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if (hashData.pid && hashData.gid) {
			openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
		}
	}

	function handleSelectStyles() {
		var x, i, j, l, ll, selElmnt, a, b, c;
		/* Look for any elements with the class "select": */
		x = document.getElementsByClassName('select');
		l = x.length;
		for (i = 0; i < l; i++) {
			selElmnt = x[i].getElementsByTagName('select')[0];
			ll = selElmnt.length;
			/* For each element, create a new DIV that will act as the selected item: */
			a = document.createElement('DIV');
			a.setAttribute('class', 'select-selected');
			a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
			x[i].appendChild(a);
			/* For each element, create a new DIV that will contain the option list: */
			b = document.createElement('DIV');
			b.setAttribute('class', 'select-items select-hide');
			for (j = 1; j < ll; j++) {
				/* For each option in the original select element, create a new DIV that will act as an option item: */
				c = document.createElement('DIV');
				c.innerHTML = selElmnt.options[j].innerHTML;
				c.addEventListener('click', function (e) {
					/* When an item is clicked, update the original select box, and the selected item: */
					var y, i, k, s, h, sl, yl;
					s = this.parentNode.parentNode.getElementsByTagName('select')[0];
					sl = s.length;
					h = this.parentNode.previousSibling;
					for (i = 0; i < sl; i++) {
						if (s.options[i].innerHTML == this.innerHTML) {
							s.selectedIndex = i;
							h.innerHTML = this.innerHTML;
							y = this.parentNode.getElementsByClassName('same-as-selected');
							yl = y.length;
							for (k = 0; k < yl; k++) {
								y[k].removeAttribute('class');
							}
							this.setAttribute('class', 'same-as-selected');
							break;
						}
					}
					h.click();
				});
				b.appendChild(c);
			}
			x[i].appendChild(b);
			a.addEventListener('click', function (e) {
				/* When the select box is clicked, close any other select boxes, and open/close the current select box: */
				e.stopPropagation();
				closeAllSelect(this);
				this.nextSibling.classList.toggle('select-hide');
				this.classList.toggle('select-arrow-active');
			});
		}

		function closeAllSelect(elmnt) {
			/* A function that will close all select boxes in the document, except the current select box: */
			var x,
				y,
				i,
				xl,
				yl,
				arrNo = [];
			x = document.getElementsByClassName('select-items');
			y = document.getElementsByClassName('select-selected');
			xl = x.length;
			yl = y.length;
			for (i = 0; i < yl; i++) {
				if (elmnt == y[i]) {
					arrNo.push(i);
				} else {
					y[i].classList.remove('select-arrow-active');
				}
			}
			for (i = 0; i < xl; i++) {
				if (arrNo.indexOf(i)) {
					x[i].classList.add('select-hide');
				}
			}
		}

		document.addEventListener('click', closeAllSelect);
	}

	function handleFiltersButton() {
		$('.btn-filters').on('click', function (e) {
			e.preventDefault();

			const $trigger = $(this);
			const headerHeight = $('.header').outerHeight(false);
			const sectionFilterHeight = $trigger.parents('.filter__head').outerHeight(false);
			const sectionFilterBodyHeight = $trigger
				.parents('.filter')
				.find('.filter__body')
				.outerHeight(false);
			const $sectionToMove = $trigger.parents('.main').find('.section--models');
			var adminBarHeight;
			if ($win.width() <= 782) {
				adminBarHeight = $('body').hasClass('admin-bar') ? 46 : 0;
			} else {
				adminBarHeight = $('body').hasClass('admin-bar') ? 32 : 0;
			}

			if ($trigger.hasClass('btn-filters-show')) {
				$sectionToMove.css(
					'top',
					`calc(${headerHeight}px + ${sectionFilterHeight}px + ${adminBarHeight}px + 1px)`
				);
				$sectionToMove.css('pointer-events', 'all');
				$trigger.find('span').text($trigger.data('show'));
			} else {
				$sectionToMove.css(
					'top',
					`calc(${headerHeight}px + ${sectionFilterHeight}px + ${adminBarHeight}px + ${sectionFilterBodyHeight}px + 1px)`
				);
				$sectionToMove.css('pointer-events', 'none');
				$trigger.find('span').text($trigger.data('hide'));
			}

			$trigger.toggleClass('btn-filters-show');
			$sectionToMove.toggleClass('section--models-show');
		});
	}

	function clearFiltersButton() {
		$('.btn-clear-filters').on('click', function (e) {
			e.preventDefault();

			const $form = $('#form-filter');
			const $select = $('#field-location');

			if ($form.length) {
				$('#form-filter input:checkbox').prop('checked', false);

				if ($select.length) {
					$select.prop('selectedIndex', -1);
					$('.select-selected').text($select.data('text'));
				}
			}
		});
	}

	function handleAllCheckboxLogic() {
		$('.checkbox span').on('click', function () {
			if (!$(this).parents('.checkbox').is(':first-child')) {
				$(this)
					.parents('.form__row-flex')
					.find('input:checkbox[value=all]')
					.prop('checked', false);
			} else {
				$(this)
					.parents('.checkbox')
					.siblings()
					.find('input:checkbox')
					.prop('checked', false);
			}
		});
	}

	function handleFiltersForm() {
		if (!$('#form-filter').length) {
			return;
		}

		$('#form-filter').on('submit', function (e) {
			e.preventDefault();

			ajaxRenderModels(false);
		});
	}

	function ajaxRenderModels(_append) {
		if (modelsLoading) {
			return;
		}

		modelsLoading = true;

		const append = typeof _append !== 'undefined' && _append;

		const $modelFilterForm = $('#form-filter');
		const modelsPayload = $modelFilterForm.serializeArray();

		const $loadMore = $('.load--more');
		let pageNumUrl = '';
		let seedNum = '';

		if (append) {
			if (!$loadMore.length) {
				modelsLoading = false;
				return;
			}

			const pageNum = parseInt($loadMore.attr('data-page-next'));
			seedNum = parseInt($loadMore.attr('data-seed-num'));
			pageNumUrl = '&page_num=' + pageNum + '&seed_num=' + seedNum;
		} else {
			seedNum = Math.floor(Math.random() * 1111) + 1;
			pageNumUrl = '&seed_num=' + seedNum;
		}

		addLoader();

		$.ajax({
			url: appData.ajaxUrl + '?action=app_get_models' + pageNumUrl,
			data: modelsPayload
		}).then((response) => {
			modelsLoading = false;

			const responseData = response.data;
			let responseJSON = responseData.markup;
			let loadMore = responseData.loadMore;

			if (responseJSON) {
				if (append) {
					$('.cards').append(responseJSON);
				} else {
					$('.cards').html(responseJSON);
				}
			} else {
				$('.cards').empty();
			}

			if (loadMore) {
				if ($loadMore.length) {
					$loadMore.attr('data-page-next', responseData.page_next);
					$loadMore.attr('data-seed-num', responseData.seed);
				} else {
					$('.section--models .shell').append(
						`<div class="load--more" data-page-next="${responseData.page_next}" data-seed-num="${responseData.seed}"></div>`
					);
				}
			} else {
				$loadMore.remove();
			}

			updateModelsCounter();
			removeLoader();
		});
	}

	function updateModelsCounter() {
		let shownModels = $('.card-model').length;
		$('.filter__head-inner .num-tar').text(shownModels);
	}

	function handleAjaxLoadMoreModels() {
		if (!$('#form-filter').length) {
			return;
		}

		$('.section--models').on('touchmove scroll', function () {
			let sectionScrollTop = $(this).scrollTop();
			let sectionInnerHeight = $(this).innerHeight();
			let sectionScrollHeight = $(this).get(0).scrollHeight;

			if (sectionScrollTop + sectionInnerHeight + 400 >= sectionScrollHeight) {
				ajaxRenderModels(true);
			}
		});
	}

	function ajaxRenderNews() {
		if (newsLoading) {
			return;
		}

		newsLoading = true;
		let $loadMore = $('.load--more');
		let pageNumUrl = '';

		if (!$loadMore.length) {
			return;
		}

		let pageNum = $loadMore.length ? parseInt($loadMore.attr('data-page-next')) : 1;
		pageNumUrl = $loadMore.length ? '&page_num=' + pageNum : '';

		addLoader();

		$.ajax({
			url: appData.ajaxUrl + '?action=app_get_news' + pageNumUrl
		}).then((response) => {
			newsLoading = false;

			let responseData = response.data;
			let responseJSON = responseData.markup;
			let loadMore = responseData.loadMore;

			if (responseJSON) {
				$('.cards').append(responseJSON);
			}

			if (loadMore) {
				if ($loadMore.length) {
					$loadMore.attr('data-page-next', responseData.page_next);
				}
			} else {
				$loadMore.remove();
			}

			removeLoader();
		});
	}

	function handleAjaxLoadMoreNews() {
		if (!$('#news-section').length) {
			return;
		}

		if (window.innerHeight + window.scrollY + 1000 >= document.body.offsetHeight) {
			ajaxRenderNews();
		}
	}

	function ajaxRenderPhotographers() {
		if (photographersLoading) {
			return;
		}

		photographersLoading = true;
		let $loadMore = $('.load--more');
		let pageNumUrl = '';

		if (!$loadMore.length) {
			return;
		}

		let pageNum = $loadMore.length ? parseInt($loadMore.attr('data-page-next')) : 1;
		let seedNum = $loadMore.length ? parseInt($loadMore.attr('data-seed-num')) : 1;
		pageNumUrl = $loadMore.length ? '&page_num=' + pageNum + '&seed_num=' + seedNum : '';

		addLoader();

		$.ajax({
			url: appData.ajaxUrl + '?action=app_get_photographers' + pageNumUrl
		}).then((response) => {
			photographersLoading = false;

			let responseData = response.data;
			let responseJSON = responseData.markup;
			let loadMore = responseData.loadMore;

			if (responseJSON) {
				$('.cards').append(responseJSON);
			}

			if (loadMore) {
				if ($loadMore.length) {
					$loadMore.attr('data-page-next', responseData.page_next);
				}
			} else {
				$loadMore.remove();
			}

			removeLoader();
		});
	}

	function handleAjaxLoadMorePhotographers() {
		if (!$('#photographer-section').length) {
			return;
		}

		if (window.innerHeight + window.scrollY + 1000 >= document.body.offsetHeight) {
			ajaxRenderPhotographers();
		}
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			e.preventDefault();
			let sectionId = $(this).attr('href');

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 120
				},
				1000
			);
		});
	}

	function initSliderTweets() {
		$('.slider-tweets .swiper-container').each((i, slider) => {
			new Swiper(slider, {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				loop: true,
				coverflowEffect: {
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true
				},
				breakpoints: {
					240: {
						slidesPerView: 1,
						spaceBetween: 30
					},
					413: {
						slidesPerView: 2,
						spaceBetween: 60
					},
					680: {
						slidesPerView: 3,
						spaceBetween: 60
					}
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		});
	}

	function initAccordion() {
		$('.accordion__head').on('click', function () {
			$(this).parents('.accordion__pannel').siblings().find('.accordion__body').slideUp(280);
			$(this).parents('.accordion__pannel').find('.accordion__body').slideToggle(280);
		});
	}

	function blurHeader() {
		if (!$('.page-template-home').length) {
			return;
		}

		$win.on('load scroll', function () {
			if ($doc.scrollTop() > 80) {
				$('.header').addClass('header--blur');
			} else {
				$('.header').removeClass('header--blur');
			}
		});
	}
})(jQuery);
